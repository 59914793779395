<template>
  <div>
    <banner/>
    <introduce/>
    <center/>
    <about/>
    <connect/>
  </div>
</template>

<script>
import banner from './banner.vue'
import introduce from './introduce.vue'
import connect from '../components/connect.vue'
import about from './about.vue'
import center from './center.vue'
export default {
  components:{
    banner,
    introduce,
    connect,
    about,
    center
  }
}
</script>

<style>

</style>