<template>
  <div class="home-banner">
    <video-player :options="videoOptions" :poster="bannerImg" width="100%" height="674px"/>
    <div class="home-banner-info">
      <h1>软件定义出行，协同创新全服务</h1>
      <p>苏州高铁新城</p>
      <div>
        <a-button class="btn-red" @click="consult">免费体验</a-button >
        <a-button @click="isConnect = !isConnect">服务咨询</a-button >
      </div>
    </div>
    <connect-modal :show="isConnect" v-model="isConnect"/>
  </div>
</template>

<script>
import videoPlayer from "./VideoPlayer.vue";
import connectModal from "@/components/Vconnect.vue";
export default {
  components: {
    videoPlayer,
    connectModal
  },
  data() {
    return {
      isConnect:false,
      videoOptions: {
        autoplay: true,
        loop: true,
        muted: true,
        controls: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/vehicles/video1.mp4",
            type: "video/mp4",
          },
        ],
      },
      bannerImg:require('@/assets/img/about.jpg')
    };
  },
  methods:{
    consult(){
      document.querySelector('.travel-connect').scrollIntoView()
    },
  }
};
</script>

<style lang="scss" scoped>
.home-banner{
    position: relative;
    .home-banner-info{
        position: absolute;
        top: 192px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        // padding-top: 192px;
        // background: rgba(000,000,000,.4);
        >h1{
            font-size: 62px;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 64px;
            margin-bottom: 46px;
            text-align: center;
        }
        >p{
            font-size: 38px;
            color: #FFFFFF;
            line-height: 36px;
            margin-bottom: 32px;
            text-align: center;
        }
        >div{
            display: flex;
            justify-content: center;
            >button{
                height: 52px;
                width: 160px;
                font-size: 20px;
                margin-left: 10px;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }
}
</style>