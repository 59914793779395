<template>
  <div class="about-center" :style="{'background':`url(${require(`@/assets/img/centerbg${aboutCenteTagIndex}.png`)}) no-repeat`}">
    <strong>鸿联联创·软件定义出行协同创新赋能6中心</strong>
    <p>鸿联联创·软件定义出行协同创新赋能鸿小二</p>
    <div>
        <div class="about-cente-info">
            <template v-for="(el,index) in list">
                <div :key="index" v-if="(index + 1) == aboutCenteTagIndex">
                    <p>{{el.title}}</p>
                    <span>{{el.info}}</span>
                    <div>
                        <span v-for="(e,i) in el.label" :key="i"><img src="@/assets/img/gou.png" :alt="e" />{{e}}</span>
                    </div>
                </div>
            </template>
            <!-- <span>服务咨询<a-icon type="right" /></span> -->
        </div>
        <div class="about-cente-tag">
            <span :class="{'active':aboutCenteTagIndex == 1}" @mouseenter="aboutCenteTagIndex = 1"><img src="@/assets/img/centericon1.png" alt="智联万物中心" />智联万物中心</span>
            <span :class="{'active':aboutCenteTagIndex == 2}" @mouseenter="aboutCenteTagIndex = 2"><img src="@/assets/img/centericon2.png" alt="智联共创中心" />智联共创中心</span>
            <span :class="{'active':aboutCenteTagIndex == 3}" @mouseenter="aboutCenteTagIndex = 3"><img src="@/assets/img/centericon3.png" alt="数智转型中心" />数智转型中心</span>
            <span :class="{'active':aboutCenteTagIndex == 4}" @mouseenter="aboutCenteTagIndex = 4"><img src="@/assets/img/centericon4.png" alt="开源创新中心" />开源创新中心</span>
            <span :class="{'active':aboutCenteTagIndex == 5}" @mouseenter="aboutCenteTagIndex = 5"><img src="@/assets/img/centericon5.png" alt="鸿联英才中心" />鸿联英才中心</span>
            <span :class="{'active':aboutCenteTagIndex == 6}" @mouseenter="aboutCenteTagIndex = 6"><img src="@/assets/img/centericon6.png" alt="未来实验中心" />未来实验中心</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            aboutCenteTagIndex:1,
            list:[{
                title:'智联万物中心',
                info:'以车联网及软件定义出行行业需求为核心，通过产业数据、信息、资源的线上聚合和线下高效协同，汇聚并刻画中软国际生态及地方国产信创、鸿蒙（鸿联）生态的信息数据、成熟产品和示范案例等，由点及面的阐述企业发展中的痛点问题，从关键工序到全生产流程，帮助企业完成转型思维训练、场景案例搭建和转型体验。',
                label:['场景案例搭建','企业转型体验','转型思维训练']
            },{
                title:'智联共创中心',
                info:'以联合创新为核心，汇聚自主创新的解决方案、产品、开发板、SOC和OpenHarmony等创新资源，联合区域内企业组建联合创新团队，提供低代码等工具，为区域内的车联网及软件定义出行行业应用和企业产品进行自主创新方案规划、指导和评估，拉通国产自主创新的器件供应链，实现国产自主创新产品的定型、推广和落地实施。',
                label:['创新资源聚合','行业应用联创','低代码工具']
            },{
                title:'数智转型中心',
                info:'以提质增效、 数字化转型、国产化替代和智转数改的精准施策、政策辅导和实施辅助为核心，提供区域车联网及软件定义出行产业数字化转型辅导与规划，提供客户需求牵引对接企业数字化转型，提供企业数字化转型&国产化替代的诊断、辅导、规划、牵引及落地，帮助企业完成政策辅导、数字化诊断和国产化咨询。',
                label:['政策辅导','数字化诊断','国产化咨询']
            },{
                title:'开源创新中心',
                info:'以开源创新为核心，借助开源社区、组织、机构和基金会等孵化推广手段为抓手转换业务机会，通过需求侧拉通企业中心能力，供给侧拉通社区和基金会生态，围绕车联网及软件定义出行共同发展技术能力，建立开源产业联盟，开展开源生态活动，汇聚开源资源，构建标准并打造标杆项目，完成最终的商业闭环。',
                label:['开源资源汇聚','开源生态活动','开源产业联盟']
            },{
                title:'鸿联英才中心',
                info:'以车联网及软件定义出行发展和区域数字化转型需求为核心，以OpenHarmony、物联网、大数据和人工智能等先进技术为依托，结合区域的产业特性和企业特点，汇聚区域IoT行业硬件产品、系统软件和应用软件等各类资源，促进区域内外高校与区内企业持续对接，协助区域建设实训基地，帮助企业进行实战培训和校企联训。',
                label:['实训基地','实战培训','校企培训']
            },{
                title:'未来实验中心',
                info:'以区域车联网及软件定义出行为核心，以OpenHarmony、物联网、大数据和人工智能等先进技术为依托，联合区域、行业协会和区域头部企业建设未来实验室，搭建创新产品实验环境、综合方案验证环境、各生态系统模拟环境，服务当地企业新产品/技术方案验证，跨企业综合方案验证和生态系统功能满足性验证。',
                label:['创新方案验证','实验环境搭建','实验室共建']
            }]
        }
    }
}
</script>

<style lang="scss" scoped>
.about-center{
    padding: 100px 0 83px;
    background-size: 100% 100%;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.97) 100%);
    }
    >strong{
        font-size: 36px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 34px;
        display: block;
        text-align: center;
        margin-bottom: 36px;
        position: relative;
        z-index: 2;
    }
    >p{
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 16px;
        margin-bottom: 80px;
        position: relative;
        z-index: 2;
    }
    >div{
        position: relative;
        z-index: 2;
        width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .about-cente-info{
            width: 1005px;
            >div{
                padding-bottom: 56px;
                // border-bottom: 1px solid rgba(247, 248, 249, .5);
                margin-bottom: 38px;
                >p{
                    font-size: 26px;
                    color: #FFFFFF;
                    line-height: 25px;
                    margin-bottom: 30px;
                }
                >span{
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 26px;
                    display: block;
                    margin-bottom: 55px;
                }
                >div{
                    display: flex;
                    >span{
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        color: #fff;
                        margin-right: 36px;
                        >img{
                            margin-right: 12px;
                        }
                    }
                }
            }
            >span{
                width: 154px;
                height: 46px;
                background: rgba(247, 248, 249, .3);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                color: #FFFFFF;
                cursor: pointer;
                >i{
                    margin-left: 8px;
                }
            }
        }
        .about-cente-tag{
            width: 214px;
            border-radius: 6px;
            background: rgba(247, 248, 249, .1);
            padding: 10px;
            >span{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 49px;
                border-radius: 4px;
                font-size: 18px;
                color: #FFFFFF;
                margin-bottom: 8px;
                transition: all .3s;
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }
                >img{
                    margin-right: 15px;
                }
                &.active{
                    background: #DE001D;
                }
            }
        }
    }
}
</style>