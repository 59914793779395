<template>
  <div class="video-player-box" :style="{width: width,height: height}" v-if="!isMobile">
    <video ref="videoPlayer" class="video-js videos" :poster="poster" :playsinline="true" :webkit-playsinline="true" :x5-playsinline="true"></video>
  </div>
  <div class="video-player-box" v-else-if="isMobile">
    <video ref="videoPlayer" class="video-js videos" :poster="poster" :playsinline="true" :webkit-playsinline="true" :x5-playsinline="true" :style="{width: width,height: height}"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    poster:{
      type:String
    },
    width:{
      type:String,
      default:'1920px'
    },
    height:{
      type:String,
      default:'680px'
    }
  },
  data() {
    return {
      player: null,
      isMobile:false
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
      this.isMobile = true
      document.querySelector('.videos').style.width = '1903px'
      document.querySelector('.videos').style.height = this.height
    }else{
      this.isMobile = false
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>
.video-player-box{
  overflow: hidden;
  position: relative;
}

.vjs-control-bar{
  display: none !important;
}
.vjs-big-play-button{
  z-index: 10;
}
.videos{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.videos::after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.video-player-box .vjs-big-play-button{
  z-index: 10;
}
</style>