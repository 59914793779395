<template>
  <div class="home-about">
    <div>
      <strong>关于鸿联</strong>
      <p>
        鸿联联创中心是中软国际打造的AloT平台品牌，致力基于平台打造自主创新AIoT生态，旨在以需求为驱动、以场景为路径、以政府为先导、以区域为单元，探索对接服务国产物联网南北向生态的新模式
      </p>
      <div>
        <img
          class="home-about-left"
          @click="aboutLeft"
          src="@/assets/img/ableft.png"
          alt="jt"
        />
        <div>
          <div>
            <div class="about-course">
              <div v-for="(item, index) in aboutInfo" :key="index">
                <img src="@/assets/img/mdicon.png" alt="mdicon" />
                <span>{{ item.date.substr(0, 4) }}</span>
                <div>
                  <p>{{ item.date }}</p>
                  <img :src="item.img" alt="mdicon" />
                  <span>{{ item.info }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="home-about-right" @click="aboutRight" src="@/assets/img/adright.png" alt="jt" />
      </div>
    </div>
  </div>
</template>

<script>
let index = 0
export default {
  data() {
    return {
      aboutInfo: [
        {
          date: "2017-7",
          info: "成为华为云首家同舟共济合作伙伴，共拓公有云市场",
          img: require("@/assets/img/ad1.png"),
        },
        {
          date: "2017-11",
          info: "发起并成功举办首届全球程序员节",
          img: require("@/assets/img/ad2.png"),
        },
        {
          date: "2019-01",
          info: "获评中国信息技术服务十年十大领军企业",
          img: require("@/assets/img/ad3.png"),
        },
        {
          date: "2019-07",
          info: "携手华为共建鲲鹏产业生态",
          img: require("@/assets/img/ad4.png"),
        },
        {
          date: "2020-04",
          info: "鲲鹏计算产业生态重庆中心揭牌成立",
          img: require("@/assets/img/ad5.png"),
        },
        {
          date: "2020-09",
          info: "与北理工战略合作，进军智能网联汽车领域",
          img: require("@/assets/img/ad6.png"),
        },
        {
          date: "2020-09",
          info: "“鸿蒙开天地，联创照未来” 中软国际（乌镇）鸿联联创中心全球首发",
          img: require("@/assets/img/ad7.png"),
        },
        {
          date: "2020-12",
          info: "荣获HUAWEI HiLink“卓越合作伙伴 生态使能服务”大奖",
          img: require("@/assets/img/ad8.png"),
        },
        {
          date: "2020-12",
          info: "华为云同舟共济合作伙伴业绩第一，实现四年 20 倍跨越式增长",
          img: require("@/assets/img/ad9.png"),
        },
        {
          date: "2021-01",
          info: "携手打造“数字化引领转型升级”标杆城市",
          img: require("@/assets/img/ad10.png"),
        },
        {
          date: "2021-01",
          info: "与北理工共建信创学院",
          img: require("@/assets/img/ad11.png"),
        },
        {
          date: "2021-04",
          info: "蝉联 Gartner2020 全球 IT 服务 TOP100，排名晋升",
          img: require("@/assets/img/ad12.png"),
        },
        {
          date: "2021-04",
          info: "入驻华为开发者联盟生态市场，打造智能物联网产品联合销售线上渠道",
          img: require("@/assets/img/ad13.png"),
        },
        {
          date: "2021-08",
          info: "与华为签署鸿蒙生态合作协议",
          img: require("@/assets/img/ad14.png"),
        },
        {
          date: "2021-08",
          info: "携手清华大学、国汽智联、国汽智控探索智能网联汽车发展新方向",
          img: require("@/assets/img/ad15.png"),
        },
        {
          date: "2021-09",
          info: "中软国际蝉联2021中国软件百强",
          img: require("@/assets/img/ad16.png"),
        },
        {
          date: "2021-10",
          info: "鸿蒙之城 有迹可循｜中软国际全面参与华为开发者大会2021",
          img: require("@/assets/img/ad17.png"),
        },
        {
          date: "2021-10",
          info: "中软国际设立智能物联网业务集团",
          img: require("@/assets/img/ad18.png"),
        },
        {
          date: "2022-01",
          info: "中软国际荣获深智城“一网统管”项目组“突出贡献奖”",
          img: require("@/assets/img/ad19.png"),
        },
        {
          date: "2022-03",
          info: "联创共赢，中软国际成为金蝶“全国性交付伙伴”",
          img: require("@/assets/img/ad20.png"),
        },
      ],
      timer:null
    };
  },
  mounted(){
    this.loadAdout()
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods: {
    aboutLeft() {
        let box = document.querySelector('.about-course')
        index = index - 1
        if(index < 0){
            index = 0
            let leftBtn = document.querySelector('.home-about-left')
            leftBtn.style.cursor = "no-drop"
            leftBtn.style.opacity = "0.65"
            return
        }else{
            let rightBtn = document.querySelector('.home-about-right')
            rightBtn.style.cursor = "pointer"
            rightBtn.style.opacity = "1"
        }
        let leftWidth = 194 * index
        box.style.left = '-' + leftWidth + 'px'
    },
    aboutRight(){
        let boxWidth = 194 * (this.aboutInfo.length - 6)
        let box = document.querySelector('.about-course')
        let nextIndex = index
        index = index + 1
        let leftWidth = 194 * index
        if(leftWidth > boxWidth){
            let rightBtn = document.querySelector('.home-about-right')
            rightBtn.style.cursor = "no-drop"
            rightBtn.style.opacity = "0.65"
            index = nextIndex
        }else{
            box.style.left = '-' + leftWidth + 'px'
        }
        if(index > 0){
            let leftBtn = document.querySelector('.home-about-left')
            leftBtn.style.cursor = "pointer"
            leftBtn.style.opacity = "1"
        }
    },
    loadAdout(){
      let boxWidth = 194 * (this.aboutInfo.length - 6)
      let box = document.querySelector('.about-course')
      this.timer = setInterval(() => {
        index = index + 1
        let leftWidth = 194 * index
        if(leftWidth > boxWidth){
            let rightBtn = document.querySelector('.home-about-right')
            rightBtn.style.cursor = "no-drop"
            rightBtn.style.opacity = "0.65"
            index = 0
            box.style.left = '0'
        }else{
            box.style.left = '-' + leftWidth + 'px'
        }
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  padding: 64px 0;
  background: url(../../assets/img/gybg.png) no-repeat;
  background-size: 100% 100%;
  > div {
    width: 1264px;
    margin: 0 auto;
    > strong {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      display: block;
      line-height: 45px;
      margin-bottom: 16px;
    }
    > p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      line-height: 28px;
      margin-bottom: 48px;
    }
    > div {
      height: 626px;
      position: relative;
      > img {
        width: 58px;
        height: 58px;
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -24px;
        cursor: pointer;
      }
      .home-about-left {
        left: 0;
      }
      .home-about-right {
        right: 0;
      }
      > div {
        width: 1152px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        > div {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          background-image: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.15) 14%,
            rgba(0, 0, 0, 0.15) 88%,
            rgba(0, 0, 0, 0) 100%
          );
          height: 2px;
          width: 100%;
          padding: 0 86px 0 86px;
          > div {
            transform: translateY(-10px);
            display: flex;
            transition: all .3s;
            position: relative;
            left: 0;
            > div {
              position: relative;
              margin-left: 174px;
              &:first-child {
                margin-left: 0;
              }
              > img {
                width: 20px;
                height: 20px;
                display: block;
              }
              > span {
                position: absolute;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 14px;
                display: block;
                left: -6px;
                top: 30px;
              }
              > div {
                position: absolute;
                width: 190px;
                height: 250px;
                background: #ffffff;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
                padding: 16px;
                left: 50%;
                margin-left: -95px;
                > p {
                  font-size: 16px;
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 22px;
                  margin-bottom: 12px;
                  font-weight: bold;
                }
                > img {
                  display: block;
                  width: 100%;
                  height: 90px;
                }
                >span{
                    display: block;
                    font-size: 14px;
                    color: rgba(0,0,0,0.65);
                    line-height: 20px;
                    margin-top: 16px;
                    // text-align: justify;
                }
                &::after {
                  content: "";
                  position: absolute;
                  left: 50%;
                  margin-left: -10px;
                }
              }
              &:nth-child(2n) {
                > div {
                  top: 64px;
                  &::after {
                    border-right: 7px solid transparent;
                    border-left: 7px solid transparent;
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid #fff;
                    top: -14px;
                  }
                }
              }
              &:nth-child(2n + 1) {
                > div {
                  top: -274px;
                  &::after {
                    border-right: 7px solid transparent;
                    border-left: 7px solid transparent;
                    border-top: 7px solid #fff;
                    border-bottom: 7px solid transparent;
                    bottom: -14px;
                  }
                }
              }
              
            }
          }
        }
      }
    }
  }
}
</style>