<template>
  <span @click="handleClick" style="cursor: pointer;">
    <slot v-if="!busy">获取验证码</slot>
    <slot v-else name="loading" :seconds="seconds"
      >{{ seconds }}秒后重新发送</slot
    >
  </span>
</template>

<script>
export default {
  name: "VCountDown",
  props: {
    max: {
      type: Number,
      default: 60,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  data() {
    return {
      seconds: 0,
      busy: false,
    };
  },
  methods: {
    async handleClick() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        await this.trigger();
        this.getCode();
      } catch (e) {
        this.busy = false;
      }
    },
    getCode() {
      const { disabled } = this;
      if (disabled) {
        return;
      }
      const { max } = this;
      this.seconds = max;
      const stop = setInterval(() => {
        this.seconds -= 1;
        if (this.seconds <= 0) {
          this.busy = false;
          clearInterval(stop);
        }
      }, 1000);
    },
  },
};
</script>
