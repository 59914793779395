<template>
  <div class="travel-connect">
    <strong>简化开发、运行和维护</strong>
    <p>激发全员数字化创造活力，软件定义出行协同创新平台为无限价值而来</p>
    <div>
      <div>
        <a-input
          style="width: 390px; height: 64px"
          placeholder="请输入公司名称"
          v-model="form.contactCompany"
          ref="consulInput"
        />
      </div>
      <div>
        <a-input
          style="width: 390px; height: 64px"
          placeholder="请输入称呼"
          v-model="form.contactPerson"
        />
      </div>
      <div>
        <a-input
          style="width: 390px; height: 64px"
          placeholder="请输入手机号"
          :maxLength="11"
          v-model="form.contactDetail"
        />
      </div>
      <a-button class="btn-red" @click="connectBtn">我要试用体验</a-button>
    </div>
  </div>
</template>

<script>
import { createByCar } from "@/api/common";
export default {
  data() {
    return {
      form: {
        consultSource:'鸿联云',
        contactCompany: "",
        contactPerson: "",
        contactDetail: "",
      },
    };
  },
  methods: {
    connectBtn() {
      let {form} = this
      if(form.contactCompany == ''){
        this.$message.warning('请输入公司名称！');
        return
      }else if(form.contactPerson == ''){
        this.$message.warning('请输入称呼！');
        return
      }
      if (!/^1[3456789]\d{9}$/.test(form.contactDetail)) {
        this.$message.warning('手机号格式不正确！');
        return
      }
      createByCar(form).then((res)=>{
        this.$message.success('提交成功!');
        setTimeout(() => {
          window.open('https://web.szgtop-vehicles.gitok.com/#/iotLogin','_blank')
        }, 1500);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.travel-connect {
  padding: 100px 0;
  background: url(../../assets/img/ads-bg.jpg) no-repeat;
  background-size: 100% 100%;
  > strong {
    display: block;
    font-size: 36px;
    color: #333333;
    line-height: 34px;
    margin-bottom: 36px;
    text-align: center;
    font-weight: 500;
  }
  > p {
    font-size: 22px;
    font-weight: 500;
    color: #666666;
    line-height: 24px;
    margin-bottom: 35px;
    text-align: center;
  }
  > div {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > div {
      > input {
        font-size: 16px;
      }
    }
    > button {
      width: 222px;
      height: 64px;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 1400px){
  .travel-connect{
    >div{
      width: 100%;
      padding: 0 24px;
      >div{
        input{
          width: 320px !important;
        }
      }
    }
  }
}
</style>