<template>
  <a-modal
    v-model="visible"
    width="548px"
    wrap-class-name="connect-modal"
    :title="null"
    :footer="null"
    cancel="connectCancel"
  >
    <div class="connect-modal-box">
      <p>我要咨询</p>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          ref="consultTitle"
          label="咨询主题"
          prop="consultTitle"
        >
          <a-input
            v-model="form.consultTitle"
            placeholder="请输入咨询主题"
            @blur="
              () => {
                $refs.consultTitle.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="咨询详情" prop="consultDesc">
          <a-input
            v-model="form.consultDesc"
            placeholder="请输入您想咨询的详细内容"
            type="textarea"
          />
        </a-form-model-item>
        <a-form-model-item ref="contactName" label="联系人" prop="contactName">
          <a-input
            v-model="form.contactName"
            placeholder="请输入真实姓名"
            :max-length="4"
            @blur="
              () => {
                $refs.contactName.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="contactPhone"
          label="联系电话"
          prop="contactPhone"
        >
          <a-input
            v-model="form.contactPhone"
            placeholder="请输入联系电话"
            :max-length="11"
            @blur="
              () => {
                $refs.contactPhone.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="contactPhoneCode"
          label="验证码"
          prop="contactPhoneCode"
        >
          <a-input
            v-model="form.contactPhoneCode"
            placeholder="请输入短信验证码"
            @blur="
              () => {
                $refs.contactPhoneCode.onFieldBlur();
              }
            "
          >
            <template slot="suffix">
              <v-count-down
                :trigger="() => sendMobileCode()"
                :disabled="isCode"
              >
                <template #default>获取验证码</template>
                <template #loading="scope"
                  >{{ scope.seconds }}秒后重新发送</template
                >
              </v-count-down>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
          <a-button class="btn-red" @click="onSubmit" style="width:136px">提 交</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import VCountDown from "@/components/VCountDown";
import axios from "axios";
export default {
  props: ["show"],
  components: {
    VCountDown,
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      isCode: true,
      form: {
        consultTitle: "",
        consultDesc: "",
        contactName: "",
        contactPhone: "",
        contactPhoneCode: "",
      },
      rules: {
        consultTitle: [
          { required: true, message: "请输入咨询主题", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        contactPhoneCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    show: function (val) {
      this.visible = val;
    },
    visible: function (val) {
      this.$emit("input", val);
    },
    "form.contactPhone": function (val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        this.isCode = true;
      } else {
        this.isCode = false;
      }
    },
  },
  mounted() {
    this.visible = this.show;
  },
  methods: {
    sendMobileCode() {
      const { contactPhone } = this.form;
      if (!contactPhone || contactPhone.length < 11) {
        this.$message.warning("手机号输入有误");
        return;
      }
      let url = `http://prod.gitok.com/portal_server/common/send-sms/${contactPhone}`;
      axios
        .post(url, {})
        .then((res) => {
            this.$message.success("发送成功！");
        })
        .catch((error) => console.log(error));
    },
    onSubmit() {
      let { form } = this;
      let _this = this;
      let url = `http://prod.gitok.com/portal_server/consult/create`;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            consultSource:'车联网',
            consultTitle: form.consultTitle,
            consultContent: form.consultDesc,
            contactDetail: form.contactPhone,
            contactPerson: form.contactName,
            contactCompany: "",
          };
          axios
            .post(url, data)
            .then((res) => {
              _this.$message.success("提交成功！");
              _this.$emit("input", false);
              _this.$refs.ruleForm.resetFields();
            })
            .catch((error) => console.log(error));
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    connectCancel() {
      this.$emit("input", false);
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-modal-box {
  > p {
    font-size: 27px;
    line-height: 38px;
    color: #262626;
    text-align: center;
    margin-bottom: 24px;
  }
}
</style>
<style>
.connect-modal .ant-modal-body {
  padding: 24px 42px;
}
.connect-modal .ant-modal-content {
  border-radius: 0;
}
</style>