<template>
  <div class="about-introduce">
    <strong>鸿联联创 · 软件定义出行协同创新平台介绍</strong>
    <div>
      <img src="@/assets/img/jsimg.png" alt="平台介绍" />
      <div>
        <div>
          <div>
            <div>
              软件定义出行协同创新平台，通过对车联网及出行行业的洞察和分析，利用SpringBoot微服务架构、可视化开发、云原生、集群部署和跨平台开发等先进技术，形成流程引擎、表单引擎、报表引擎、视图引擎、接口引擎、组织引擎和门户引擎的七大引擎的软件定义出行平台，解决车联网及出行行业的人员规模与代码规模的不适配、业务需求与开发效率的不适配和人员规模与代码规模的不适配的三大痛点，缩短开发周期，降低开发成本，提升开发质量，简化车联网及出行行业的开发、运行和运维工作，激发全员数字化创造活力。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      introduceTagIndex: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.about-introduce {
  padding: 100px 0 80px;
  background: #fff;
  > strong {
    font-size: 36px;
    color: #333333;
    line-height: 34px;
    display: block;
    margin-bottom: 55px;
    text-align: center;
  }
  > div {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 737px;
      height: 440px;
    }
    > div {
      width: 529px;
      padding-top: 24px;
      > div {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        > div {
          > div {
            font-size: 14px;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>